.m-trade-kline {
  max-height: rem(360);
  overflow: hidden;
  @include dz();
  &.hide {
    max-height: 0;
  }
  &-layout {
    height: rem(300);
  }
  &-inner {
    height: rem(480);
    width: 160vw;
    display: flex;
    flex-flow: column;
    @include fd(0.625);
    transform-origin: left top;
  }
  &-view-switch {
    height: rem(24);
    border: none;
    background: $dark1;
    border-radius: rem(12);
    padding: 0 rem(10);
    margin: rem(5) auto;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: block;
      height: rem(24);
      @include dz();
      width: rem(24);
      background: url($res + 'icon/arrow-up-tab.svg') center/rem(12) auto no-repeat;
    }
    &.hide:before {
      @include xz(180);
    }
  }
}
