.page-launchpad-item-requirement {
  margin-bottom: 30px;
  h3 {
    font-size: 14px;
    color: $text3;
    margin: 25px 0 20px;
    font-weight: normal;
  }
  ul {
    display: flex;
    justify-content: space-between;

    li {
      width: 175px;
      height: 64px;
      background: $dark4;
      border-radius: 16px;
      padding-left: 70px;
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 12px;
        left: 16px;
        background: center/contain no-repeat;
      }

      label {
        display: block;
        font-size: 12px;
        color: $text2;
        opacity: 0.65;
        margin-bottom: 8px;
      }
      em {
        display: block;
        font-size: 14px;
        font-weight: bold;
      }

      &:nth-child(1):before {
        background-image: url($res + 'icon/human-green.svg');
      }
      &:nth-child(2):before {
        background-image: url($res + 'icon/data-green.svg');
      }
      &:nth-child(3):before {
        background-image: url($res + 'icon/exchange-green.svg');
      }
    }
  }
}

.mobile-mode {
  .page-launchpad-item-requirement {
    margin-bottom: rem(30);
    h3 {
      font-size: rem(14);
      margin: rem(25) 0 rem(20);
    }
    section {
      overflow-x: auto;
    }
    ul {
      display: block;
      white-space: nowrap;
      li {
        display: inline-flex;
        margin-right: rem(14);
        width: auto;
        height: rem(56);
        border-radius: rem(14);
        padding-left: rem(60);
        padding-right: rem(12);
        &:before {
          width: rem(34);
          height: rem(34);
          top: rem(10);
          left: rem(12);
        }

        label {
          font-size: rem(10);
          margin-bottom: rem(5);
        }
        em {
          font-size: rem(12);
        }
      }
    }
  }
}
