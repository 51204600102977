.page-trade-bench-type-select {
  display: flex;
  align-items: center;

  button {
    width: 106px;
    height: 38px;
    border: none;
    background: none;
    color: $text3;
    font-size: 13px;
    border-right: 1px solid $line;
    cursor: pointer;
    background: $bg;
    @include dz();

    &.active {
      color: $text1;
      background: $dark3;
    }
  }
}

.mobile-mode {
  .page-trade-bench-type-select {
    button {
      width: rem(125);
      height: rem(38);
      font-size: rem(13);
    }
  }
}
