.page-earn-modal {
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    a {
      display: inline-flex;
      align-items: center;
      gap: 6px;

      &::after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        display: block;
        background: url($res + 'icon/link-arrow.svg') center/contain no-repeat;
      }
    }
  }
}
