.page-earn-pool-lp {
  background: $dark3;
  border-radius: 8px;
  padding: 0 32px 32px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    height: 86px;
    border-radius: 8px 8px 0 0;
    margin: 0 -32px 14px;
    @include jb(rgba(#4b6cb7, 0.3), rgba(#182848, 0.3), -90);
    main {
      h4 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        color: $text3;
        line-height: 16px;
      }
    }
    aside {
      width: 145px;
      padding-top: 5px;
    }
  }

  // overwrite
  .web-c-skeleton-content .web-c-skeleton-row {
    width: 60px !important;
    height: 14px;
  }
}

.mobile-mode {
  .page-earn-pool-lp {
    border-radius: rem(8);
    padding: 0 rem(16) rem(24);
    &-header {
      padding: 0 rem(16);
      height: rem(86);
      border-radius: rem(8) rem(8) 0 0;
      margin: 0 rem(-16) rem(14);
      main {
        h4 {
          font-size: rem(16);
          line-height: rem(20);
          margin-bottom: rem(10);
        }
        p {
          font-size: rem(14);
          line-height: rem(16);
        }
      }
      aside {
        width: rem(145);
        padding-top: rem(5);
      }
    }
  }
}
.staking {
  color: $blue;
}

.closed {
  color: $text3;
}

.unopened {
  color: $yellow;
}

.finish {
  color: $green;
}
