.page-trade-orders {
  height: 231px;
  position: relative;
  .react-tabs {
    &__tab {
      position: relative;
      font-size: 13px;
      cursor: pointer;
      border-right: 1px solid $line;
      color: $text3;
      @include dz();
      .c-tag {
        margin-left: 8px;
        opacity: 0.6;
      }
      &-list {
        display: flex;
        align-items: center;
        border-top: 1px solid $line;
        li {
          border-bottom: 1px solid $line;
          height: 44px;
          display: flex;
          align-items: center;
          padding: 0 24px;
        }
      }
      &--selected {
        // background: $lBlue;color: $white;
        color: $text1;
        border-bottom-color: $bg !important;
        .c-tag {
          opacity: 1;
        }
      }
      &:focus-visible {
        outline: none;
      }
      &-panel {
        display: none;
        &--selected {
          display: block;
          // overflow: auto;
          // height: 284px;
        }
      }
    }
  }

  &-view-switch {
    flex: 1;
    justify-content: center;
    button {
      display: flex;
      width: 40px;
      height: 100%;
      border: none;
      cursor: pointer;
      background: url($res + 'icon/arrow-up-tab.svg') center/14px auto no-repeat;
      @include dz();
    }
    &.open button {
      @include xz(180);
    }
  }
  &-inner {
    background: $bg;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    overflow: hidden;
    @include dz(500ms);
    &.open {
    }
  }
}

.m-trade-orders {
  padding: 0 rem(16) rem(24);
  position: relative;
  &-tabs {
    border-top: 1px solid $line;
    width: 100%;
    overflow: auto;
    ul {
      // display: flex;align-items: center;
      display: grid;
      grid-template-columns: auto auto auto auto;
      li {
        margin-right: rem(28);
        white-space: nowrap;
        font-size: rem(15);
        height: rem(50);
        display: flex;
        align-items: center;
        color: $text3;
        strong,
        em {
          display: block;
        }
        strong {
          font-weight: normal;
        }
        em {
          margin-left: rem(2);
        }
        &.active {
          color: $text1;
        }
      }
    }
  }
  &-panel {
  }
}
