.web-c-card {
  display: flex;
  justify-content: center;
  margin: 30px 0 36px;

  &-inner {
    position: relative;

    &-img {
      img {
        width: 160px;
        height: 160px;
        border-radius: 10px;

        &:not(:first-child) {
          position: absolute;
          left: 0;
        }

        @for $x from 2 through 3 {
          $y: $x - 1;
          &:nth-of-type(#{$x}) {
            bottom: -$y * 16px;
            z-index: -$y;
            opacity: 1 - $y * 0.25;
            transform: scale(1.1 - $x * 0.1);
          }
        }
      }
    }

    label {
      color: $cff;
      font-size: 12px;
      background: rgba($cff, 0.05);
      position: absolute;
    }

    &-label1 {
      right: 5px;
      top: 5px;
      border-radius: 23px;
      padding: 0 6px;
    }

    &-label2 {
      bottom: 0;
      left: 0;
      right: 0;
      backdrop-filter: blur(16px);
      border-radius: 10px;
      text-align: center;
      padding: 3px 0;
    }
  }
}
