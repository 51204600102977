.page-earn-panel {
  padding: 32px 32px 0;
  background: $dark2;
  border-radius: 8px;
  height: 380px;

  &-title {
    font-size: 16px;
    color: $text1;
    margin-bottom: 24px;
  }

  &-card {
    &s {
      display: flex;
      justify-content: space-around;
    }
    &-add {
      img {
        width: 120px;
        height: 120px;
        border-radius: 12px;
        cursor: pointer;
        display: block;
      }
      em,
      small {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background: $dark2;
        font-size: 12px;
        color: $text2;
        width: 120px;
        height: 120px;
        border: 1px dashed $text3;
        border-radius: 12px;
        cursor: pointer;
        @include dz();

        &:before {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url($res + 'icon/plus.svg') center/contain no-repeat;
        }
        &:hover {
          background: $dark1;
        }
      }
      small {
        &:before {
          width: 0;
          height: 0;
        }
      }
      footer {
        margin-top: 12px;
        text-align: center;
        span {
          font-size: 14px;
          line-height: 16px;
          display: block;
          margin-bottom: 5px;
          color: $text2;
        }
        strong {
          font-size: 16px;
          line-height: 20px;
          display: block;
        }

        // overwrite
        .web-c-skeleton-content .web-c-skeleton-row {
          width: 100% !important;
          height: 20px;
          margin: 0 auto;
        }
      }
    }

    &-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-top: 16px;
      grid-template-rows: 1fr 1fr;
      // grid-template-areas: 'a b'
      //   'c c';
      // & > * {
      //   &:nth-child(1) { grid-area: a }
      //   &:nth-child(2) { grid-area: b }
      //   &:nth-child(3) { grid-area: c }
      // }
    }
  }

  &-nfts {
    &-wrap {
      display: grid;
      grid-gap: 20px 6px;
      grid-template-columns: repeat(4, 25%);
      max-height: 480px;
      overflow: hidden auto;
    }
    .c-table-empty-layout {
      width: auto;
      .c-loading-box {
      }
    }
    button {
      margin: 0 auto;
      display: flex;
      width: 284px;
    }
    &-select {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      button {
        width: 100px;
        margin: 0 10px;
      }
    }
  }

  &-redeem {
    small {
      display: inline-flex;
      color: $orange;
      padding-bottom: 10px;
    }

    dl:nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      dt {
        margin-right: 16px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 5px;
        }
      }

      dd {
        p:nth-of-type(1) {
          font-size: 18px;
          color: $cf7;
        }

        p:nth-of-type(2) {
          color: $text3;
          margin-top: 6px;
          font-size: 14px;
        }
      }
    }
    dl:nth-of-type(2) {
      padding: 0 10px;
      margin-bottom: 8px;

      dt {
        font-size: 14px;
        height: 32px;
        display: flex;
        align-items: center;
      }

      dd {
        display: flex;
        color: $text2;
        height: 32px;
        font-size: 14px;
        align-items: center;
        justify-content: space-between;
      }
    }

    .c-data-item {
      font-size: 14px;
      height: 32px;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        .c-data-item-inner {
          font-size: 14px;
        }
      }

      &:nth-of-type(2) {
        .c-data-item-inner {
          color: $fall;
          font-weight: 700;
        }
      }
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: 80px !important;
    }
  }
}

.mobile-mode {
  .page-earn-panel {
    padding: rem(24) rem(16);
    border-radius: rem(8);
    height: auto;

    &-title {
      font-size: rem(16);
      margin-bottom: rem(24);
    }

    &-card {
      &s {
        display: flex;
        justify-content: space-around;
      }
      &-add {
        img {
          width: rem(120);
          height: rem(120);
          border-radius: rem(12);
        }
        em {
          font-size: rem(12);
          width: rem(120);
          height: rem(120);
          border-radius: rem(12);

          &:before {
            width: rem(24);
            height: rem(24);
          }
        }
        footer {
          margin-top: rem(12);
          span {
            font-size: rem(14);
            line-height: rem(16);
            margin-bottom: rem(5);
          }
          strong {
            font-size: rem(16);
            line-height: rem(20);
          }

          // overwrite
          .web-c-skeleton-content .web-c-skeleton-row {
            height: rem(20);
          }
        }
      }

      &-footer {
        grid-gap: rem(16);
        margin-top: rem(16);
      }
    }

    &-nfts {
      &-wrap {
        grid-gap: rem(10);
        grid-template-columns: 1fr 1fr;
        max-height: rem(300);
        // overflow-x: auto;
      }
      .c-table-empty-layout {
        width: auto;
        .c-loading-box {
        }
      }
      button {
        width: 100%;
      }
      &-select {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        button {
          width: 100px;
          margin: 0 10px;
        }
      }
    }
    &-redeem {
      small {
        padding: rem(10) 0 0;
      }

      dl:nth-of-type(1) {
        margin-bottom: rem(16);

        dt {
          margin-right: rem(16);

          img {
            width: rem(80);
            height: rem(80);
            border-radius: rem(5);
          }
        }

        dd {
          p:nth-of-type(1) {
            font-size: rem(18);
          }

          p:last-child {
            margin-top: rem(6);
            font-size: rem(14);
          }
        }
      }
      dl:nth-of-type(2) {
        padding-left: rem(10);
        margin-bottom: rem(8);

        dt {
          font-size: rem(14);
          height: rem(32);
        }

        dd {
          height: rem(32);
          font-size: rem(14);
        }
      }

      .c-data-item {
        font-size: rem(14);
        height: rem(32);

        &:nth-of-type(1),
        &:nth-of-type(2) {
          .c-data-item-inner {
            font-size: rem(18);
          }
        }
      }
    }
  }
}
