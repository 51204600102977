.page-launchpad-item-status-bar {
  height: 70px;
  padding: 12px;
  border-radius: 35px;
  background: $dark2;
  box-shadow: 0 1px 1px rgba($white, 0.2) inset;
  width: 505px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  li {
    cursor: pointer;

    & > i {
      float: left;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background: $dark4;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include dz();
      img {
        display: block;
        max-width: 26px;
        max-height: 26px;
        opacity: 0.3;
      }
    }
    aside {
      width: 0;
      overflow: hidden;
      height: 46px;
      display: flex;
      align-items: center;
      background: $white;
      border-radius: 23px;
      @include dz();
      color: $dark3;

      & > i {
        padding: 0 15px;
        display: block;
        img {
          display: block;
          width: 30px;
          height: 30px;
          opacity: 0.3;
        }
      }

      strong {
        opacity: 0.3;
        display: block;
        padding-right: 20px;
      }
      em {
        font-size: 14px;
      }
      time {
        display: block;
        span {
          font-size: 16px;
          line-height: 20px;
          display: block;
        }
        small {
          font-size: 12px;
          line-height: 14px;
          display: block;
          margin-top: 2px;
        }
      }
    }

    &.done {
      i img,
      strong {
        opacity: 1;
      }
      aside {
        background: #fcd2fb;
      }
    }
    &.active {
      & > i {
        width: 0;
      }
      aside {
        width: 280px;
      }
    }
  }
}

.mobile-mode {
  .page-launchpad-item-status-bar {
    height: rem(48);
    padding: rem(8);
    border-radius: rem(24);
    width: 100%;
    margin-top: rem(26);
    li {
      & > i {
        width: rem(32);
        height: rem(32);
        img {
          max-width: rem(15);
          max-height: rem(15);
        }
      }
      aside {
        height: rem(32);
        border-radius: rem(16);

        & > i {
          padding: 0 rem(8);
          img {
            width: rem(20);
            height: rem(20);
          }
        }

        strong {
          font-size: rem(12);
          width: auto;
          padding-right: rem(10);
        }
        em {
          font-size: rem(12);
        }
        time {
          display: block;
          span {
            font-size: rem(9);
            line-height: 1.2;
          }
          small {
            font-size: rem(7);
            line-height: 1;
          }
        }
      }

      &.active {
        & > i {
          width: 0;
        }
        aside {
          width: rem(180);
        }
      }
    }
  }
}
