.c-copy-button {
  img {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.mobile-mode {
  .c-copy-button {
    img {
      width: rem(20);
      height: rem(20);
    }
  }
}
