.page-trade-bench-fee-tip {
  &-switch {
    img {
      width: 16px;
      height: 16px;
      display: block;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  &-modal {
    margin: 10px 0 8px;
    height: 72px;
    padding: 16px 20px;
    background: url($res + 'icon/light-modal-bg.svg') center/contain no-repeat;
    border-radius: 12px;
    z-index: 5;
    overflow: hidden;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    &:before {
      content: '';
      display: block;
      width: 80px;
      height: 200px;
      @include xz(45);
      position: absolute;
      top: -80px;
      left: -150px;
      @include jb(rgba($white, 0.1), transparent, 90);
      @include dz(0.35s);
    }
    &:hover {
      &:before {
        // left: 140px;
        left: 280px;
      }
    }
    header,
    section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;
    }
    header {
      margin-bottom: 10px;
      label,
      span {
        font-size: 10px;
        color: $text3;
      }
      em {
        color: $text2;
      }
    }
    section {
      strong {
        font-size: 16px;
        font-weight: bold;
        color: $text2;
        small {
          font-size: 10px;
          color: $text3;
          font-weight: normal;
          margin-left: 5px;
        }
      }
      button {
        padding: 0 20px;
        border: none;
        background: linear-gradient(74.9deg, #d6d3f3 17.26%, #bfcef8 48.59%, #f3d3df 83.1%);
        height: 20px;
        border-radius: 10px;
        position: relative;
        top: 0;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        @include dz();
        color: $black;
        &:hover {
          top: -2px;
        }
      }
    }
    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: 50px !important;
      height: 16px;
    }
  }
}
.mobile-mode {
  .page-trade-bench-fee-tip {
    &-switch {
      img {
        width: rem(16);
        height: rem(16);
        margin-left: rem(5);
      }
    }
    &-modal {
      height: rem(72);
      padding: rem(16) rem(20);
      width: auto;
      border-radius: rem(12);
      background-size: cover;
      margin: rem(10) 0 rem(8);
      header {
        margin-bottom: rem(10);
        label,
        span {
          font-size: rem(10);
        }
      }
      section {
        strong {
          font-size: rem(16);
          small {
            font-size: rem(10);
            margin-left: rem(5);
          }
        }
        button {
          padding: 0 rem(20);
          height: rem(20);
          border-radius: rem(10);
          font-size: rem(12);
        }
      }
      // overwrite
      .web-c-skeleton-content .web-c-skeleton-row {
        width: rem(50) !important;
        height: rem(16);
      }
    }
  }
}
