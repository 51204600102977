.page-earn {
  min-height: 100%;
  background: $dark4;
  padding-bottom: 100px;

  &-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    @extend .web;
  }

  &-pools {
    @extend .web;
    margin-top: 56px;

    &-header {
      margin-bottom: 40px;

      h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: $text2;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }
  }
}

.mobile-mode {
  .page-earn {
    padding: rem(24) rem(16);

    &-one {
      grid-template-columns: 1fr;
      grid-gap: rem(24);
    }

    &-pools {
      @extend .web;
      margin-top: rem(24);

      &-header {
        margin-bottom: rem(24);

        h3 {
          font-size: rem(24);
          line-height: rem(30);
          margin-bottom: rem(10);
        }

        p {
          font-size: rem(16);
        }
      }

      &-body {
        grid-template-columns: 1fr;
        grid-gap: rem(24);
      }
    }
  }
}
