.page-trade-bench-multiple {
  flex: 1;
  height: 38px;
  border: none;
  background: none;
  color: $text3;
  font-size: 13px;
  cursor: pointer;
  background: $bg;
  display: flex;
  align-items: center;
  justify-content: center;
  @include dz();

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    margin-left: 10px;
    background: url($res + 'icon/arrow-down-mini.svg') center/contain no-repeat;
    @include dz();
  }

  &.show {
    background: $dark3;
    color: $text1;
    &:after {
      @include xz(180);
    }
  }
  &-modal {
    overflow-y: unset;
    .web-c-skeleton-row {
      height: 40px;
    }
  }
}
.mobile-mode {
  .page-trade-bench-multiple {
    height: rem(38);
    font-size: rem(13);

    &:after {
      width: rem(6);
      height: rem(6);
      margin-left: rem(10);
    }
  }
}
