.page-earn-banner {
  height: 120px;
  padding: 0 56px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: url($res + 'assets/dashboard-banner-bg.png') right center/auto 93px no-repeat;

  @extend .web_;

  section {
    @extend .web;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: $text2;
  }
}
.mobile-mode {
  .page-earn-banner {
    height: auto;
    padding: 0;
    background: none;
    margin-bottom: rem(24);

    h2 {
      font-size: rem(24);
      line-height: rem(30);
      margin-bottom: rem(10);
    }

    p {
      font-size: rem(16);
    }
  }
}
