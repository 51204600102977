.page-trade-bench {
  &-header {
    display: flex;
    align-items: center;
    height: 40px;
    border: solid $line;
    border-width: 1px 0;
  }
  &-main {
    padding: 20px 24px;
  }
  &-count {
    border-top: 1px solid $line;
    margin-top: 20px;
    padding-top: 12px;
    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      font-size: 13px;
      color: $text3;
      position: relative;
      dd {
        text-transform: uppercase;
      }
      dt {
        display: flex;
        align-items: center;
      }
    }
  }
  &-insure-gear {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .c-button {
      padding: 0 12px;
      font-weight: normal;
    }
  }
  &-insurance {
    margin-top: 10px;
    margin-bottom: -10px;
    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      font-size: 13px;
      color: $text3;
      dd {
        text-transform: uppercase;
      }
    }
  }
  &-line {
    height: 1px;
    border: none;
    background: $line;
    margin: 20px 0;
  }
}

.mobile-mode {
  .page-trade-bench {
    margin: 0 rem(-24);
    &-header {
      height: rem(40);
    }
    &-main {
      padding: rem(20) rem(24);
    }
    &-count {
      margin-top: rem(20);
      padding-top: rem(12);
      dl {
        height: rem(32);
        font-size: rem(14);
      }
    }
    &-insure-gear {
      .c-button {
        padding: 0 rem(12);
      }
    }
    &-insurance {
      margin-top: rem(10);
      margin-bottom: rem(-10);
      dl {
        height: rem(32);
        font-size: rem(14);
      }
    }
    &-line {
      margin: rem(20) 0;
    }
  }
}
