.page-trade-symbol-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  border-bottom: 1px solid $line;
  padding: 0 16px;
  position: relative;
  z-index: 5;
  background: $dark3;
  .c-symbol {
    text-transform: uppercase;
  }
  & > button {
    display: flex;
    text-transform: uppercase;
    border: none;
    color: $text3;
    font-size: 13px;
    background: none;
    align-items: center;
    cursor: pointer;
    @include dz();
    &:hover {
      color: $text2;
    }
    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      margin-left: 10px;
      background: url($res + 'icon/arrow-down-mini.svg') center no-repeat;
      @include dz();
    }
  }
  &.show {
    z-index: 100;
    & > button:after {
      @include xz(180);
    }
  }

  &-options.show &-options-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21;
    @include mh(4px);
  }
  &-options {
    &-list {
      position: fixed;
      top: 84px;
      right: 0;
      z-index: 22;
      height: 0;
      overflow: hidden;
      width: 323px;
      display: flex;
      flex-flow: column;
    }
    ul {
      flex: 1;
      overflow-y: auto;
      background: $dark3;
      @include dz();
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        height: 58px;
        padding: 0 16px;
        border-bottom: 1px solid $line;
        & > aside {
          display: flex;
          flex-flow: column;
          align-items: flex-end;
          & > em {
            font-size: 15px;
            color: $white;
            font-weight: bold;
            margin-bottom: 6px;
            display: block;
          }
        }
        // .c-symbol {
        //   text-transform: uppercase;
        // }

        // overwrite
        .web-c-skeleton-content .web-c-skeleton-row {
          width: 80px !important;
          height: 20px;
        }
      }
    }
    &.show &-list {
      height: calc(100vh - 120px);
    }
  }
  &-search {
    padding: 12px;
    background: $dark3;
    border-bottom: 1px solid $line;
  }
}

.mobile-mode {
  .page-trade-symbol-select {
    height: rem(48);
    padding: 0 rem(16);
    & > h4 {
      font-size: rem(16);
    }
    & > button {
      font-size: rem(13);
      &:after {
        width: rem(6);
        height: rem(6);
        margin-left: rem(10);
        @include xz(180);
      }
    }

    &-options {
      &-list {
        top: 0;
      }
      ul {
        li {
          height: rem(58);
          padding: 0 rem(16);
          & > aside {
            & > em {
              font-size: rem(15);
              margin-bottom: rem(6);
              font-weight: normal;
            }
          }
          // overwrite
          .web-c-skeleton-content .web-c-skeleton-row {
            width: rem(80) !important;
            height: rem(20);
          }
        }
      }
      &.show .page-trade-symbol-select-options-list {
        height: 100vh;
        width: 100%;
      }
    }
    &-search {
      padding: rem(12);
    }
  }
}
