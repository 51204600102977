.page-trade {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  &-main {
    flex: 1;
    width: calc(100% - 324px);
    display: flex;
    flex-flow: column;
    position: relative;
    // height: calc(100% - 36px);
    // height: calc(100% - 6px);
    height: 100%;
  }
  &-bar {
    width: 324px;
    border-left: 1px solid $line;
    // height: calc(100% - 36px);
    height: 100%;
    overflow-y: auto;
  }
}
