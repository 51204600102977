.web-paper {
  background: #1c1c28;
  @extend .web_;
  min-height: 100vh;
  @extend .cf;
  padding-bottom: 200px;
  &-content,
  &-title {
    max-width: 1100px;
    margin: 0 auto;
    color: #fff;
    line-height: 2;
  }

  &-title {
    font-size: 48px;
    text-align: center;
  }

  &-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 24px;
      margin: 45px 0 20px;
    }
    h3 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
    h4 {
      font-size: 20px;
      margin: 35px 0 20px;
    }
    h5 {
      font-size: 18px;
      margin: 30px 0 20px;
    }
    h6 {
      font-size: 18px;
      margin: 30px 0 20px;
    }
    p {
      color: #c3c2d4;
      margin: 16px 0;
      font-size: 16px;
    }
    strong {
      color: #f0f0f0;
    }
  }
}

.mobile-mode {
  .web-paper {
    padding-bottom: rem(50);
    &-content,
    &-title {
      max-width: 100%;
      padding: 0 rem(16);
    }

    &-title {
      font-size: rem(32);
    }

    &-content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: rem(10);
        margin-bottom: rem(10);
      }
      h2 {
        font-size: rem(18);
      }
      h3 {
        font-size: rem(16);
      }
      h4 {
        font-size: rem(16);
      }
      h5 {
        font-size: rem(14);
      }
      h6 {
        font-size: rem(14);
      }
      p {
        color: #c3c2d4;
        margin: rem(8) 0;
        font-size: rem(12);
      }
    }
  }
}
