.mobile-mode {
  .m-trade-curr-order,
  .m-trade-funds,
  .m-market-mining-holding-list {
    &-header {
      padding: rem(6) rem(12);
      p,
      aside {
        display: flex;
        flex-flow: column;
        font-size: rem(12);
        // justify-content: center;
        span {
          color: $text2;
        }
        em {
          color: $text3;
          margin-top: rem(5);
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    &-item {
      padding: rem(12);
      border-radius: rem(8);
      margin-bottom: rem(12);
      &.long {
        @include jb(#1f1f33, #202b2f, 135);
      }
      &.short {
        @include jb(#1f1f33, #2f2020, 135);
      }
      .c-conversion-inner p + p {
        display: none;
      }
      .c-conversion-inner p {
        font-size: rem(13);
        color: $text2;
      }

      &-col {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: rem(40);
        font-size: rem(13);
        color: $text2;

        &:last-child {
          text-align: right;
          align-items: flex-end;
        }
        & > span {
          line-height: rem(16);
        }
      }
    }
  }
  .m-trade-curr-order {
    &-header,
    &-item {
      display: grid;
      grid-template-columns: 1fr 0.6fr 0.8fr;
    }
    &-item {
      &-col {
        &:first-child {
          justify-content: center;
        }
      }
    }
  }
  .m-trade-funds {
    &-header,
    &-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      background: $dark2;
    }
  }
}
