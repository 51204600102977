.page-earn-vest-item {
  background: $dark3;
  border-radius: 8px;

  &-header {
    @include jb(rgba(#416dbd, 0.3), rgba(#13294a, 0.3), 90);
    border-radius: 8px 8px 0 0;
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > main {
      display: flex;
      flex-flow: column;
      gap: 10px;

      & > h4 {
        font-size: 16px;
      }

      & > p {
        font-size: 14px;
        color: $text3;
      }
    }

    & > aside {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: $text3;
    }
  }
  &-data {
    padding: 24px 32px;
    display: flex;
    flex-flow: column;
    gap: 16px;

    & > dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 18px;

      & > dt {
        font-size: 14px;
        color: $text3;
      }

      & > dd {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 14px;

        & > .link {
          width: 20px;
          height: 20px;
          display: block;
          background: url($res + 'icon/link-arrow.svg') center/contain no-repeat;
        }
      }
    }
  }
  &-footer {
    display: grid;
    padding: 0 32px 32px;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
