.page-launchpad {
  @extend .sweb;

  &-layout {
    background: $dark3;
    padding-top: 90px;
    min-height: 100%;
    @extend .web_;
  }
  &-apply-btn {
    position: fixed;
    left: calc(50% + 570px);
    bottom: 15%;
  }
}

.mobile-mode {
  .page-launchpad {
    &-layout {
      padding: rem(16);
    }
    &-apply-btn {
      position: fixed;
      left: auto;
      right: rem(16);
      bottom: rem(16);
    }
  }
}
