.web-coming-soon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: $dark4 url($res + 'assets/dashboard-banner-bg.png') right top/auto 93px no-repeat;
  @extend .web_;
  img {
    display: block;
    width: 100px;
    height: 100px;
  }
  p {
    font-size: 14px;
    color: $text3;
  }
}

.mobile-mode {
  .web-coming-soon {
    height: calc(100vh - rem(62));
  }
}
