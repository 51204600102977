.c-table {
  font-size: 13px;
  color: $text2;
  background: $bg;
  $self: &;
  &-content {
    border: none;
    border-radius: 0;
  }
  table {
    width: 100%;
  }
  th,
  td {
    border: none;
  }
  thead {
    tr {
      //border-bottom: 1px solid $line;
    }
    th {
      background: $bg;
      padding: 0 10px;
      text-align: left;
      font-size: 13px;
      color: $text3;
      height: 40px;
      font-weight: normal;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $line;
      th,
      td {
        background: none;
        padding: 0 10px;
        height: 48px;
        line-height: 1.4;
      }
      &:hover {
        background: $bg;
      }
    }
  }
  #{$self}-cell#{$self}-cell-row-hover {
    background: none;
  }
  &-tbody {
    .c-table-placeholder:first-child td {
      background: $bg !important;
      height: 160px;
      font-size: 12px;
      color: $text3;
      padding-bottom: 36px;
    }
    .c-table-placeholder td {
      text-align: center;
      background: $bg !important;
      height: 160px;
      font-size: 12px;
      color: $text3;
      padding-bottom: 36px;
    }
  }

  &-empty-layout {
    width: calc(100vw - 352px);
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-ping-right &-cell,
  &-has-fix-right &-cell {
    &-fix-right {
      background: $bg !important;
      &-first {
        padding-left: 30px;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 10px;
          top: 0;
          left: -10px;
          bottom: 0;
          position: absolute;
          // @include jb($bg, rgba($black, .2), -90);
          background: linear-gradient(90deg, rgba(23, 23, 34, 0) 10%, $bg 90.75%);
        }
      }
    }
  }

  // overwrite
  .liquidation-price {
    color: $text4;
  }
  .web-c-skeleton {
    padding: 18px 0;
    .web-c-skeleton-row {
      width: 100% !important;
      height: 24px;
    }
  }
  &.control-max-height {
    .c-table-body {
      max-height: none !important;
    }
  }
}

.mobile-mode {
  .c-table {
    font-size: rem(13);
    thead {
      th {
        padding: 0 rem(10);
        font-size: rem(13);
        height: rem(40);
      }
    }
    tbody {
      tr {
        th,
        td {
          padding: 0 rem(10);
          height: rem(48);
        }
      }
    }

    &-tbody {
      .c-table-placeholder:first-child td {
        height: rem(160);
        font-size: rem(12);
        padding-bottom: rem(36);
      }
      .c-table-placeholder td {
        text-align: center;
        height: rem(160);
        font-size: rem(12);
        padding-bottom: rem(36);
      }
    }

    &-empty-layout {
      width: 100%;
      min-height: rem(100);
      font-size: rem(12);
    }

    &-ping-right &-cell,
    &-has-fix-right &-cell {
      &-fix-right {
        &-first {
          padding-left: rem(30);
          &:before {
            width: rem(10);
            left: rem(-10);
          }
        }
      }
    }

    // overwrite
    .web-c-skeleton {
      padding: rem(18) 0;
      .web-c-skeleton-row {
        height: rem(24);
      }
    }
  }
}

.c-small-table {
  width: 100%;
  text-align: center;
  font-size: 14px;

  th,
  td {
    padding: 15px 0;
  }

  th {
    color: $text3;
    font-weight: normal;
    align-items: center;
  }

  td {
    color: $cff;
  }
}
.mobile-mode {
  .c-small-table {
    font-size: rem(14);
    th,
    td {
      padding: rem(15) 0;
    }
  }
}

.c-table-marketing {
  color: $text3;
  background: $dark4;
  text-align: center;

  thead {
    th {
      background: $bg;
      color: $text1;
      height: 68px;
      text-align: center;
      font-size: 14px;
    }
  }
  tbody {
    tr {
      th,
      td {
        height: 64px;
        font-size: 15px;
      }
    }
  }
}
