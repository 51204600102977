.page-trade-dashboard-detail {
  display: flex;
  height: 58px;
  border-bottom: 1px solid $line;
  padding: 0 10px;
  flex-wrap: wrap;

  &-item {
    padding-left: 30px;
    position: relative;
    height: 58px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      background: $line;
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -12px;
    }
    label {
      display: block;
      font-size: 11px;
      color: $text3;
      height: 12px;
      margin-bottom: 5px;
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      //width: 60px!important;
      height: 16px;
    }
  }
  &-value {
    font-size: 13px;
    color: $text2;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
}

.mobile-mode {
  .m-trade-dashboard-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(16);
    border-bottom: 1px solid $line;
    &-symbol {
      min-width: rem(150);
      height: rem(58);
      border-right: 1px solid $line;
      padding-right: rem(16);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c-symbol {
        &-icon {
          width: rem(32);
          height: rem(32);
        }
        &-name {
          font-size: rem(18);
          color: $text1;
          margin-bottom: rem(4);
        }
      }
      &:after {
        content: '';
        display: block;
        width: rem(6);
        height: rem(4);
        margin-left: rem(16);
        background: url($res + 'icon/arrow-down-mini.svg') center/contain no-repeat;
        @include dz();
      }
      &.active {
        &:after {
          @include xz(180);
        }
      }
    }
    &-roe {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      .c-symbol-price {
        font-size: rem(18);
      }
      * + * {
        margin-top: rem(4);
      }
    }
  }
}
