.test {
  margin: 50px;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h1 {
    margin: 20px 0 10px;
  }
}

.bbb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ccc {
  height: 100px;
  overflow: auto;
  background: red;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
