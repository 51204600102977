.page-trade-bench-direction-select {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: $dark3;
  button {
    flex: 1;
    cursor: pointer;
    width: 136px;
    height: 40px;
    border-radius: 6px;
    border: 2px solid $dark3;
    background: $dark3;
    font-size: 13px;
    color: $text3;
    &.active {
      font-weight: bold;
    }
    &:first-child.active {
      color: $green;
      border-color: $green;
    }
    &:last-child.active {
      color: $red;
      border-color: $red;
    }
  }
}

.mobile-mode {
  .page-trade-bench-direction-select {
    border-radius: rem(6);
    button {
      flex: 1;
      width: rem(136);
      height: rem(40);
      border-radius: rem(6);
      font-size: rem(13);
    }
  }
}
