.page-trade-dashboard-hotindex {
  height: 36px;
  width: 100%;
  border-bottom: 1px solid $line;
  padding: 0 8px;

  &-item {
    padding: 0 5px;
    font-size: 12px;

    label {
      margin-right: 5px;
      cursor: pointer;
    }

    .c-row-show {
      min-width: 50px;
    }
  }

  // overwrite
  .web-c-skeleton {
    height: 24px;
    align-items: center;

    .web-c-skeleton-content {
      .skeleton-rows {
        display: flex;
        align-items: center;
      }

      .web-c-skeleton-row {
        height: 22px;
        width: 100px !important;
        margin-bottom: 0;
        margin-left: 6px;
      }
    }
  }
}
