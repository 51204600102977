.c-popper {
  display: inline-flex;
  &-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  &.large &-icon {
    width: 24px;
    height: 24px;
  }
  &-content {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 30;
    // @include yd(calc(-50% + 8px), calc(-100% - 10px));
    @include yd(calc(-50% + 8px), calc(30px));

    p {
      background: $dark1;
      padding: 8px 10px;
      border-radius: 6px;
      line-height: 1.4;
      text-align: center;
      position: relative;
      font-size: 12px;
      color: $text2;
      box-shadow: 2px 2px 2px rgba($dark3, 0.3);
    }
    &:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: $dark1;
      @include xz(45);
      position: absolute;
      left: calc(50% - 6px);
      top: -6px;
      box-shadow: -2px -2px 2px rgba($dark3, 0.3);
      // bottom: -6px;box-shadow: 2px 2px 2px rgba($dark3, .3);
    }

    &.large {
      @include yd(calc(-50% + 12px), 36px);
    }
  }
  &-itemdata {
    dl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      font-size: 12px;
      dt {
        margin-right: 20px;
        color: $text2;
      }
      dd {
        color: $text1;
      }
    }
  }
  &-itemdata {
    dl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      font-size: 12px;
      dt {
        margin-right: 20px;
        color: $text2;
      }
      dd {
        color: $text1;
      }
    }
  }
}

.mobile-mode {
  .c-popper {
    &-icon {
      width: rem(15);
      height: rem(15);
    }
    &.large .c-popper-icon {
      width: rem(20);
      height: rem(20);
    }
    &-content {
      width: rem(150);
      @include yd(calc(-50% + rem(8)), calc(rem(30)));

      p {
        padding: rem(8) rem(10);
        border-radius: rem(6);
        font-size: rem(12);
      }
      &:after {
        width: rem(12);
        height: rem(12);
        top: rem(-6);
        box-shadow: rem(-2) rem(-2) rem(2) rgba($dark3, 0.3);
      }
    }
    &-itemdata {
      dl {
        height: rem(20);
        font-size: rem(12);
        dt {
          margin-right: rem(20);
        }
      }
    }
  }
}
