.mobile-mode {
  .m-trade-position {
    &-item {
      padding: rem(15) rem(12);
      border-radius: rem(8);
      margin-bottom: rem(12);
      &.long {
        @include jb(#1f1f33, #202b2f, 135);
      }
      &.short {
        @include jb(#1f1f33, #2f2020, 135);
      }

      & > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .c-symbol-name {
          font-size: rem(14);
        }
        .c-symbol-full .c-symbol-multiple {
          font-size: rem(14);
          margin-top: rem(4);
        }
        .c-symbol-size-default .c-symbol-icon {
          width: rem(38);
          height: rem(38);
        }
        & > aside {
          display: flex;
          flex-flow: column;
          align-items: flex-end;
          & > label {
            font-size: rem(12);
            color: $text3;
            display: block;
            margin-bottom: rem(8);
          }
          .c-conversion-inner p + p {
            display: none;
          }
        }
      }
      & > main {
        padding: rem(14) 0;
        dl {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: rem(6) 0;
          font-size: rem(14);
          dt {
            color: $text3;
          }
          dd {
            color: $text2;
          }
        }
      }
      & > footer {
        display: grid;
        grid-template-columns: auto rem(40);
        grid-gap: rem(20);
      }
    }

    &-modal {
      & > header {
        padding: rem(16) rem(12);
        border-radius: rem(8);
        &.long {
          @include jb(#1f1f33, #202b2f, 135);
        }
        &.short {
          @include jb(#1f1f33, #2f2020, 135);
        }
        .c-symbol {
          display: flex;
          &-name {
            font-size: rem(14);
            color: $text1;
          }
          &-icon {
            width: rem(24);
            height: rem(24);
          }
          &-full {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &-multiple {
            font-size: rem(14);
            margin-top: 0;
          }
        }
      }
      & > main {
        padding: rem(24) rem(12);
        & > dl {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: rem(6) 0;
          font-size: rem(14);
          min-height: rem(35);
          dt {
            color: $text3;
            display: flex;
            align-items: center;
            img {
              margin-left: rem(5);
            }
          }
          dd {
            color: $text2;
            text-align: right;
          }
        }
      }
      & > footer {
      }
    }
  }
}
