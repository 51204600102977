.page-launchpad-item-gen {
  &-claim,
  &-confirm,
  &-successful {
    p {
      font-size: 12px;
      text-align: center;
      padding-bottom: 6px;
      color: $text2;
      em {
        color: $red;
      }
    }
  }

  &-claim,
  &-confirm {
    .c-data-item {
      font-size: 14px;
    }
  }

  dl {
    display: flex;
    align-items: center;

    dt {
      margin-right: 16px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
      }
    }

    dd {
      p:first-child {
        font-size: 14px;
        margin-bottom: 6px;
      }

      p:last-child {
        color: $cff;
        font-size: 20px;
      }
    }
  }

  .c-data-item {
    font-size: 14px;

    &.total {
      .c-data-item-inner {
        font-size: 14px;
        color: $red;
        font-weight: bold;
      }
    }
  }

  .c-input-number-label label {
    font-size: 14px;
    padding-left: 0;
  }

  // overwrite
  .web-c-skeleton-content .web-c-skeleton-row {
    width: 80px !important;
    height: 20px;
  }
}

.web-c-card {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;

  &-inner {
    position: relative;

    &-img {
      img {
        width: 160px;
        height: 160px;
        border-radius: 10px;

        &:not(:first-child) {
          position: absolute;
          left: 0;
        }

        @for $x from 2 through 3 {
          $y: $x - 1;
          &:nth-of-type(#{$x}) {
            bottom: -$y * 16px;
            z-index: -$y;
            opacity: 1 - $y * 0.25;
            transform: scale(1.1 - $x * 0.1);
          }
        }
      }
    }

    label {
      color: $cff;
      font-size: 12px;
      background: rgba($c00, 0.5);
      position: absolute;

      &:first-child {
        right: 5px;
        top: 5px;
        border-radius: 23px;
        padding: 0 6px;
      }

      &:nth-of-type(2) {
        bottom: 0;
        left: 0;
        right: 0;
        backdrop-filter: blur(16px);
        border-radius: 10px;
        text-align: center;
        padding: 3px 0;
      }
    }
  }
}

.mobile-mode {
  .page-launchpad-item-gen {
    &-claim,
    &-confirm,
    &-successful {
      p {
        font-size: rem(12);
      }
    }
    &-confirm {
      .c-data-item {
        font-size: rem(14);
        // margin-top: rem(6)
      }
    }
    dl {
      dt {
        margin-right: rem(16);
        img {
          width: rem(80);
          height: rem(80);
          border-radius: rem(5);
        }
      }

      dd {
        p:first-child {
          font-size: rem(14);
          margin-bottom: rem(6);
        }

        p:last-child {
          font-size: rem(20);
        }
      }
    }

    .c-data-item {
      font-size: rem(14);

      &.total {
        .c-data-item-inner {
          font-size: rem(18);
        }
      }
    }

    .c-input-number-label label {
      font-size: rem(14);
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: rem(80) !important;
      height: rem(20);
    }
  }

  .web-c-card {
    margin: rem(30) 0 rem(40);

    &-inner {
      &-img {
        img {
          width: rem(160);
          height: rem(160);
          border-radius: rem(10);

          @for $x from 2 through 3 {
            $y: $x - 1;
            &:nth-of-type(#{$x}) {
              bottom: rem(-$y * 16);
            }
          }
        }
      }

      label {
        font-size: rem(12);

        &:first-child {
          right: rem(5);
          top: rem(5);
          border-radius: rem(23);
          padding: 0 rem(6);
        }

        &:nth-of-type(2) {
          border-radius: rem(10);
          padding: rem(3) 0;
        }
      }
    }
  }
}
