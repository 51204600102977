.page-launchpad-item {
  padding-bottom: 120px;

  &-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .countdown {
      gap: 10px;
      padding-top: 20px;
      display: flex;
      font-size: 20px;
      justify-content: center;

      section {
        gap: 3px;
        display: flex;
        align-items: baseline;

        span {
          width: 24px;
        }

        small {
          font-size: 12px;
          &:last-child {
            width: 30px;
          }
        }
      }
    }
  }

  &-card {
    padding: 16px;
    border-radius: 28px;
    background: rgba($white, 0.1);

    img,
    video {
      border-radius: 20px;
      width: 360px;
      height: 360px;
      display: block;
    }
  }

  &-detail {
    width: 640px;

    & > h2 {
      line-height: 40px;
      margin: 10px 0 12px;
      font-size: 32px;
    }

    & > p {
      font-size: 16px;
      line-height: 2;
      color: $text3;
    }
  }

  &-btn {
    min-width: 275px;
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
}

.mobile-mode {
  .page-launchpad-item {
    padding-bottom: rem(60);

    &-body {
      flex-flow: column;

      .countdown {
        gap: 10px;
        padding-top: rem(20);
        font-size: rem(16);

        section {
          span {
            width: rem(20);
          }

          small {
            &:last-child {
              width: rem(24);
            }
          }
        }
      }
    }

    &-card {
      padding: rem(12);
      border-radius: rem(24);

      img,
      video {
        border-radius: rem(18);
        width: rem(319);
        height: rem(319);
      }
    }

    &-detail {
      width: 100%;

      & > h2 {
        line-height: rem(25);
        margin: rem(28) 0 rem(12);
        font-size: rem(20);
      }

      & > p {
        font-size: rem(14);
        line-height: rem(20);
      }
    }

    &-btn {
      width: 100%;
    }
  }
}
