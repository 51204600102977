.page-trade-bench-order-modal {
  @include dz();
  &-base {
    margin-bottom: 6px;
    max-height: 232px;
    overflow: hidden;
    @include dz();
    section {
      background: $dark2;
      border-radius: 6px;
      padding: 12px;
      label {
        display: block;
        font-size: 12px;
        color: $text2;
      }
      p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        em {
          font-size: 16px;
          font-weight: bold;
          color: $text1;
        }
      }
    }
    aside {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;

      &:before {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background: url($res + 'icon/short-round.svg') center/contain no-repeat;
      }
    }
    .c-data-item-group:nth-of-type(1) {
      .c-data-item > label {
        color: $text2;
      }
    }
  }
  &.long &-base aside:before {
    background-image: url($res + 'icon/long-round.svg');
  }

  &-slippage {
    margin-top: 24px;
    display: flex;
  }

  &-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    background: $dark2;
    height: 36px;
    border-radius: 6px;
    padding: 0 10px;
    cursor: pointer;
    color: $text2;
    margin-bottom: 24px;
    font-size: 14px;
    @include dz();
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      opacity: 0.8;
      background: url($res + 'icon/arrow-down.svg') center/contain no-repeat;
      @include dz();
    }
  }

  &-detail {
    overflow: hidden;
    margin-bottom: 0;
    @include dz();
  }

  &.show-detail &-toggle:after {
    @include xz(180);
  }
  &.show-detail &-detail {
    max-height: 258px;
    margin-bottom: 12px;
  }
  &.show-detail &-base {
    max-height: 0;
    margin-bottom: 0;
  }
  &.show-detail &-toggle {
    margin-bottom: 12px;
  }
}
