.page-earn-pool-item {
  padding: 32px 32px;
  background: $dark3;
  border-radius: 8px;
  position: relative;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $line;
    padding-bottom: 16px;
    margin-bottom: 16px;

    main {
      h4 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: $text2;
        line-height: 16px;
      }
    }

    aside {
      width: 115px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url($res + 'assets/eran-pools-roe-bg.svg') center/contain no-repeat;
      font-size: 20px;
      font-weight: bold;
      padding-top: 10px;
    }

    &-lock {
      aside {
        font-size: 14px;
      }
    }

    &.flexible:before {
      content: '';
      display: block;
      width: 90px;
      height: 52px;
      position: absolute;
      left: 0;
      top: 0;
      background: url($res + 'assets/flexible-tag.svg') top left/contain no-repeat;
    }

    &.finished:before {
      background: url($res + 'assets/finished-tag.svg') top left/contain no-repeat;
    }
  }

  &-price {
    padding-bottom: 16px;

    dl {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;

      dt {
        color: $text3;
      }

      dd {
        color: $text1;
        display: flex;
        align-items: center;

        .c-popper {
          margin-left: 5px;
        }
      }
    }

    p {
      font-size: 14px;
    }
  }

  &-apr {
    margin-bottom: 20px;
    font-size: 14px;

    main {
      background: $dark2;
      border-radius: 8px;
      padding: 12px;

      header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .tips {
          color: $text3;
          display: flex;
          align-items: center;

          .c-popper {
            margin-left: 8px;
          }
        }

        strong {
          font-size: 14px;
          color: $l-green;
        }
      }

      p,
      aside {
        display: flex;
        justify-content: space-between;

        span {
        }
      }
    }
  }

  &-claim {
    font-size: 14px;
    margin-bottom: 24px;

    strong {
      display: block;
      color: $yellow;
      font-weight: normal;
    }

    p,
    aside {
      display: flex;
      justify-content: space-between;
      color: $text1;
    }

    dl {
      display: flex;
      justify-content: space-between;

      dd {
        color: $text1;
        display: flex;
        align-items: center;

        .c-popper {
          margin-left: 5px;
        }
      }
    }
  }

  &-footer {
    grid-template-columns: 1fr 1fr 1fr;

    &,
    &-vest {
      display: grid;
      grid-gap: 16px;
    }

    &-vest {
      grid-template-columns: 1fr 1fr;
    }

    &-lock {
      grid-template-columns: 1fr 1fr;
    }
  }

  &-vest {
  }

  &-stake {
    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 28px 0 12px;

      button {
        font-weight: normal;
        min-width: 100px;

        &:first-child {
          margin-right: 16px;
        }

        &.c-button-type-dark {
          color: $text2;
        }

        &.c-button-type-default {
          color: $cf7;
        }
      }
    }

    .lock-pool-desc {
      color: $text2;
      display: flex;
      flex-direction: column;

      span {
        padding-bottom: 10px;
      }

      span:first-child {
        color: $yellow;
      }
    }

    .c-input-number-label {
      label {
        padding-left: 0;
      }
    }
  }

  // overwrite
  .web-c-skeleton-content .web-c-skeleton-row {
    width: 60px !important;
    height: 14px;
  }
}

.mobile-mode {
  .page-earn-pool-item {
    padding: rem(24) rem(16);
    border-radius: rem(8);

    &-header {
      padding-bottom: rem(16);
      margin-bottom: rem(16);

      main {
        h4 {
          font-size: rem(16);
          line-height: rem(20);
          margin-bottom: rem(10);
        }

        p {
          font-size: rem(14);
          line-height: rem(16);
        }
      }

      aside {
        width: rem(115);
        height: rem(46);
        font-size: rem(20);
        padding-top: rem(10);
      }

      &-lock {
        aside {
          font-size: rem(14);
        }
      }
    }

    &-price {
      padding-bottom: rem(16);

      dl {
        font-size: rem(14);
        line-height: rem(16);
        margin-bottom: rem(16);
      }
    }

    &-apr {
      grid-gap: rem(16);
      margin-bottom: rem(20);
      font-size: rem(13);

      main {
        border-radius: rem(8);
        padding: rem(12);

        header {
          margin-bottom: rem(12);

          span {
            font-size: rem(11);
          }

          strong {
            font-size: rem(14);
          }
        }

        p {
          font-size: rem(12);

          span {
          }
        }
      }
    }

    &-claim {
      margin-bottom: rem(24);

      strong {
        margin-bottom: rem(12);
      }

      p {
        font-size: rem(14);
      }
    }

    &-footer {
      &,
      &-vist {
        display: grid;
        grid-gap: rem(16);
      }

      &-vist {
      }
    }

    &-vest {
    }

    &-stake {
      &-type {
        margin: rem(28) 0 rem(12);
      }
    }
  }
}
