.page-trade-kline {
  flex: 1;
  &-temp {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text3;
    height: 100%;
  }
}
