.page-trade-wallet {
  width: 304px;
  height: 37px;
  border-radius: 12px;
  padding: 10px 24px;
  overflow: hidden;
  background: url($res + 'wallet-bar-bg.png') top center/100% auto no-repeat;
  @include dz();

  &-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
  }
  &-connect {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 192px;

    &:before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background: url($res + 'icon/wallet.svg') center/contain no-repeat;
    }
    p {
      margin: 14px 40px 24px;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
      color: $text2;
    }
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include dz();
    height: 17px;
    button {
      border: none;
      width: 24px;
      height: 24px;
      display: block;
      position: relative;
      left: 10px;
      background: url($res + 'icon/arrow-up-round.svg') center/contain no-repeat;
      cursor: pointer;
      @include dz();
      @include xz(180);
    }
    h4 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $text1;
      font-weight: bold;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fadeIn;
      .c-popper {
        margin-left: 10px;
      }
      span {
        font-weight: normal;
      }
    }
  }
  &.show {
    height: 160px;
    header {
      height: 24px;
      button {
        @include xz(360);
      }
    }

    section {
      opacity: 1;
    }
  }

  section {
    margin: 12px 0;
    opacity: 0;
    @include dz();
    color: $text1;
    strong,
    span {
      display: block;
    }
    strong {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 6px;
    }
    span {
      opacity: 0.5;
      font-size: 12px;
      line-height: 16px;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
  }

  &-bench {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    &-ld {
      height: 40px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $text1;
      font-weight: bold;
      background: $dark2;
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    &-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      height: 14px;
      width: 40px !important;
    }
  }

  &-data {
    margin-bottom: -8px;
    padding: 12px 12px 20px;
    background: $dark3;
    border-radius: 6px;
  }

  // overwrite
  .web-c-skeleton-content .web-c-skeleton-row {
    height: 16px;
    width: 80px !important;
  }
}

.mobile-mode {
  .page-trade-wallet {
    width: rem(327);
    height: rem(37);
    border-radius: rem(12);
    padding: rem(10) rem(24);

    &-layout {
      padding: rem(10) 0;
    }
    &-connect {
      height: rem(192);

      &:before {
        width: rem(32);
        height: rem(32);
      }
      p {
        margin: rem(14) rem(40) rem(24);
        font-size: rem(14);
        line-height: rem(18);
      }
    }

    header {
      height: rem(17);
      button {
        width: rem(24);
        height: rem(24);
        left: rem(10);
      }
      h4 {
        font-size: rem(14);
        .c-popper {
          margin-left: rem(10);
        }
      }
    }
    &.show {
      height: rem(160);
      header {
        height: rem(24);
      }
    }

    section {
      margin: rem(12) 0;
      strong {
        font-size: rem(24);
        line-height: rem(30);
        margin-bottom: rem(6);
      }
      span {
        font-size: rem(12);
        line-height: rem(16);
      }
    }

    &-bench {
      &-ld {
        height: rem(40);
        padding: 0 rem(12);
        font-size: rem(16);
        img {
          width: rem(20);
          height: rem(20);
        }
      }

      // overwrite
      .web-c-skeleton-content .web-c-skeleton-row {
        height: rem(14);
        width: rem(40) !important;
      }
    }

    &-data {
      margin-bottom: rem(-8);
      padding: rem(12) rem(12) rem(20);
      border-radius: rem(6);
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      height: rem(16);
      width: rem(80) !important;
    }
  }
}
