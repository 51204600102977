.page-earn-card {
  width: 170px;
  padding: 12px;
  background: $dark2;
  box-shadow: 0 0 72px rgba($c00, 0.04);
  border-radius: 10px;
  cursor: pointer;
  @include ns();

  dl {
    dt {
      position: relative;
      img {
        width: 146px;
        height: 146px;
        border-radius: 10px;
      }
      .c-checkBox {
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
    dd {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      span:nth-of-type(1) {
        color: $cff;
        font-size: 14px;
        font-weight: 700;
      }
      span:nth-of-type(2) {
        color: $red;
        font-size: 12px;
        padding-top: 4px;
      }
    }
  }
}
.mobile-mode {
  .page-earn-card {
    width: rem(142);
    padding: rem(10);
    border-radius: rem(8);

    dl {
      dt {
        img {
          width: rem(122);
          height: rem(122);
          border-radius: rem(6);
        }
        .c-checkBox {
          right: rem(-4);
          top: rem(-4);
        }
      }
      dd {
        margin-top: rem(8);
        span:nth-of-type(1) {
          font-size: rem(12);
        }
        span:nth-of-type(2) {
          font-size: rem(10);
          padding-top: 0;
        }
      }
    }
  }
}
