.page-launchpad-item-data {
  margin-top: 60px;

  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 34px;
    font-size: 14px;
    color: $blue;
    margin: 24px auto 4px;
    font-weight: bold;
    background: $dark2;
    border-radius: 36px;
    img {
      margin-right: 6px;
    }
  }

  &-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 16px;
    border: 2px solid $dark2;
    dl {
      padding: 24px 42px;
      border-right: 2px solid $dark2;
      border-bottom: 2px solid $dark2;

      &:nth-child(3n) {
        border-right: 0;
      }
      &:nth-last-child(-n + 3) {
        border-bottom: 0;
      }
      dt {
        color: $text3;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
      }
      dd {
        font-size: 20px;
        min-height: 28px;
        font-weight: bold;
        display: flex;
        align-items: center;
        small {
          font-size: 12px;
          display: inline-block;
          margin: 0 0 0 5px;
        }
        img {
          width: 16px;
          height: 16px;
          display: block;
          margin-left: 5px;
        }
        em {
          padding: 0 5px;
        }
        &.baseline {
          align-items: baseline;
        }
        // overwrite
        .web-c-skeleton-content .web-c-skeleton-row {
          width: 120px !important;
          height: 28px;
        }
      }
    }
  }
}

.mobile-mode {
  .page-launchpad-item-data {
    margin-top: rem(32);

    &-link {
      width: rem(188);
      height: rem(34);
      font-size: rem(14);
      margin: rem(24) auto rem(4);
      border-radius: rem(36);
      img {
        margin-right: rem(6);
      }
    }

    &-inner {
      grid-template-columns: 1fr;
      border-radius: rem(16);
      border: 2px solid $dark2;
      dl {
        padding: rem(16);
        border-right: 0;
        border-bottom: 2px solid $dark2;

        &:nth-last-child(-n + 3) {
          border-bottom: 2px solid $dark2;
        }
        dt {
          font-size: rem(12);
          line-height: rem(14);
          margin-bottom: rem(10);
        }
        dd {
          font-size: rem(16);
          min-height: auto;

          small {
            font-size: rem(12);
            margin: 0 rem(5);
          }
          img {
            width: rem(16);
            height: rem(16);
            display: block;
            margin-left: rem(5);
          }
          em {
            padding: 0 rem(5);
          }

          // overwrite
          .web-c-skeleton-content .web-c-skeleton-row {
            width: rem(120) !important;
            height: rem(28);
          }
        }
      }
    }
  }
  // .page-launchpad-item-data {
  //   padding: rem(24);
  //   margin-top: rem(32);
  //   border-radius: rem(16);

  //   &-link {
  //     width: rem(188);
  //     height: rem(34);
  //     font-size: rem(14);
  //     margin: rem(24) auto rem(4);
  //     border-radius: rem(36);
  //     img {
  //       margin-right: rem(6);
  //     }
  //   }

  //   &-inner {
  //     grid-template-columns: 1fr 1fr;
  //     grid-gap: rem(12);

  //     dl {
  //       dt {
  //         font-size: rem(12);
  //         line-height: rem(14);
  //         margin-bottom: rem(5);
  //       }
  //       dd {
  //         font-size: rem(14);
  //         line-height: rem(28);
  //         display: block;
  //         small {
  //           font-size: rem(12);
  //           margin: 0 rem(5);
  //           display: inline-block;
  //         }
  //         img {
  //           width: rem(16);
  //           height: rem(16);
  //           display: inline-block;
  //           margin-left: rem(5);
  //         }
  //         em {
  //           padding: 0 rem(5);
  //         }
  //         &.baseline {
  //           align-items: baseline;
  //         }
  //         // overwrite
  //         .web-c-skeleton-content .web-c-skeleton-row {
  //           width: rem(120) !important;
  //           height: rem(28);
  //         }
  //       }
  //     }
  //   }
  // }
}
