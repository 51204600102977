$table-class-prefix: 'web-c-table';

.#{$table-class-prefix} {
  position: relative;
  color: $text2;
}

.#{$table-class-prefix}-layout-fixed .#{$table-class-prefix}-content-inner {
  overflow-x: auto;
  overflow-y: hidden;
}

.#{$table-class-prefix}-layout-fixed table {
  table-layout: fixed;
}

thead > .#{$table-class-prefix}-tr > .#{$table-class-prefix}-th {
  border-bottom: 1px solid $line;
}

//tbody > .#{$table-class-prefix}-tr {
//  &:last-child{.web-c-table-td{border-bottom: none;}}
//}

.#{$table-class-prefix} table {
  min-width: 100%;
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: separate;
}

.#{$table-class-prefix}-th {
  box-sizing: border-box;
  text-align: left;
  color: $text3;
  //background-color: ;
  line-height: 1.5;
  font-weight: 500;
  font-size: 13px;
  height: 41px;
}

.#{$table-class-prefix}-td {
  box-sizing: border-box;
  text-align: left;
  //background-color: ;
  border-bottom: 1px solid $line;
  line-height: 1.5;
  font-size: 13px;
  height: 48px;
}

.#{$table-class-prefix}-cell {
  width: 100%;
  padding: 0 10px;
}

.#{$table-class-prefix}-th-item {
  padding: 0 10px;
  position: relative;
}

.#{$table-class-prefix}-content-scroll {
  width: 100%;
  overflow: hidden;
}

.#{$table-class-prefix}-header {
  scrollbar-color: transparent transparent;
  //background-color: ;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: -8px;
}

.#{$table-class-prefix}-header::-webkit-scrollbar {
  background-color: transparent;
}

.#{$table-class-prefix}-body {
  position: relative;
  overflow-y: scroll;
  //background-color: ;
  z-index: 1;

  &.control-max-height {
    max-height: none !important;
  }
}
