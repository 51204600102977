.c-input {
  border: none;
  background: none;
  color: $text2;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 100%;
  background: $dark2;
  border-radius: 6px;
  padding: 8px 12px;
  &::placeholder {
    color: $text3;
  }
}
.mobile-mode {
  .c-input {
    font-size: rem(16);
    height: rem(40);
    border-radius: rem(6);
    padding: rem(8) rem(12);
  }
}
