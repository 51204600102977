.page-earn-cast {
  &-header,
  &-claim {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      display: block;
      font-size: 14px;
      color: $text3;
      line-height: 16px;
      margin-bottom: 12px;
    }

    p,
    aside {
      color: $text1;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }

    img {
      display: block;
      width: 48px;
      //height: 48px;
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: 100px !important;
      height: 24px;
    }
  }
  &-claim {
    margin: 28px auto 28px;
  }
  &-info {
    border: 1px solid $line;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 84px;
    margin-bottom: 28px;

    main {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $text3;
        line-height: 16px;
        margin-bottom: 8px;
      }
      p {
        color: $text1;
        font-size: 18px;
        font-weight: bold;
      }

      &:first-child {
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 1px;
          background: $line;
          position: absolute;
          right: 0;
          top: 20px;
          bottom: 20px;
        }
      }
    }
  }
  &-footer {
    p,
    aside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      font-size: 14px;

      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $text3;
        line-height: 16px;

        .c-popper {
          margin-left: 8px;
        }
      }
    }

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: 60px !important;
      height: 16px;
    }
  }
}

.mobile-mode {
  .page-earn-cast {
    &-header,
    &-claim {
      label {
        font-size: rem(14);
        line-height: rem(16);
        margin-bottom: rem(12);
      }

      p,
      aside {
        font-size: rem(20);
        line-height: rem(24);
      }

      img {
        width: rem(48);
        height: rem(48);
      }
    }
    &-claim {
      margin: rem(28) auto rem(28);
    }
    &-info {
      border-radius: rem(8);
      height: rem(84);
      margin-bottom: rem(28);

      main {
        label {
          font-size: rem(14);
          line-height: rem(16);
          margin-bottom: rem(8);
        }
        p {
          font-size: rem(18);
        }

        &:first-child {
          &:after {
            top: rem(20);
            bottom: rem(20);
          }
        }
      }
    }
    &-footer {
      p,
      aside {
        margin-top: rem(16);
        font-size: rem(14);

        label {
          font-size: rem(14);
          line-height: rem(16);
        }
        .c-popper {
          margin-left: rem(8);
        }
      }

      // overwrite
      .web-c-skeleton-content .web-c-skeleton-row {
        width: rem(60) !important;
        height: rem(16);
      }
    }
  }
}
