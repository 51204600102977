.lion-dex {
  height: 100vh;
}
.web-html {
  min-width: 1200px;
  // min-height: 900px;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.web-body {
  // flex: 1;
  height: calc(100vh - 48px);
  width: 100%;
  min-width: 1200px;
  overflow-y: auto;
}

.mobile-mode {
  .lion-dex {
    height: auto;
  }
}
