.page-earn-farm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  grid-gap: 30px;
  @extend .web;
  &-item {
    background: $dark2;
    border-radius: 8px;

    &-header {
      @include jb(rgba(#416dbd, 0.3), rgba(#13294a, 0.3), 90);
      border-radius: 8px 8px 0 0;
      padding: 20px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > main {
        display: flex;
        flex-flow: column;
        gap: 10px;

        & > h4 {
          font-size: 16px;
        }

        & > p {
          font-size: 14px;
          color: $text3;
        }
      }

      & > aside {
        width: 115px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url($res + 'assets/eran-pools-roe-bg.svg') center/contain no-repeat;
        font-size: 20px;
        font-weight: bold;
        padding-top: 10px;
      }
    }

    &-body {
      padding: 28px 32px;
      display: flex;
      flex-flow: column;
      gap: 24px;

      & > .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > main {
          display: flex;
          flex-flow: column;
          gap: 16px;

          & > label {
            font-size: 14px;
            color: $text3;
          }

          & > em {
            font-size: 18px;
            font-weight: bold;
          }
        }

        & > aside {
          display: flex;
          align-items: center;
          gap: 16px;

          .add,
          .sub {
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: none;
            background: center/contain no-repeat;
            cursor: pointer;
          }
          .add {
            background-image: url($res + 'assets/earn-add.svg');
          }
          .sub {
            background-image: url($res + 'assets/earn-sub.svg');
          }
        }
      }
    }

    &-toggle {
      margin: 0 32px;
      padding: 16px 0 24px;
      border-top: 1px solid $dark2;
      display: block;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @include ns();

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: url($res + 'icon/arrow-right.svg') center/12px no-repeat;
        @include xz(-90);
      }

      &.active {
        &::after {
          @include xz(90);
        }
      }
    }

    &-data {
      padding: 0 32px 32px;
      display: none;
      flex-flow: column;
      gap: 12px;

      &.show {
        display: flex;
      }

      & > dl {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > dt {
          font-size: 14px;
          color: $text3;
        }

        & > dd {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 14px;
          font-weight: bold;

          & > .link {
            width: 20px;
            height: 20px;
            display: block;
            background: url($res + 'icon/link-arrow.svg') center/contain no-repeat;
          }
        }
      }

      & > footer {
        display: flex;
        justify-content: center;
        margin-top: 12px;
        font-size: 14px;

        a {
          color: $text3;
          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
}
.mobile-mode {
  .page-earn-farm {
    grid-template-columns: 1fr;
    grid-gap: rem(30);
    &-item {
      border-radius: rem(8);

      &-header {
        border-radius: rem(8) rem(8) 0 0;
        padding: rem(20) rem(32);

        & > main {
          gap: rem(10);

          & > h4 {
            font-size: rem(16);
          }

          & > p {
            font-size: rem(14);
          }
        }

        & > aside {
          width: rem(115);
          height: rem(46);
          font-size: rem(20);
          padding-top: rem(10);
        }
      }

      &-body {
        padding: rem(28) rem(32);
        gap: rem(24);

        & > .row {
          & > main {
            gap: rem(16);

            & > label {
              font-size: rem(14);
            }

            & > em {
              font-size: rem(18);
            }
          }

          & > aside {
            gap: rem(16);

            .add,
            .sub {
              width: rem(32);
              height: rem(32);
            }
          }
        }
      }

      &-toggle {
        margin: 0 rem(32);
        padding: rem(16) 0 rem(24);
        gap: rem(8);

        &::after {
          width: rem(20);
          height: rem(20);
        }
      }

      &-data {
        padding: 0 rem(32) rem(32);
        gap: rem(12);

        & > dl {
          & > dt {
            font-size: rem(14);
          }

          & > dd {
            gap: rem(12);
            font-size: rem(14);

            & > .link {
              width: rem(20);
              height: rem(20);
            }
          }
        }

        & > footer {
          margin-top: rem(12);
          font-size: rem(14);
        }
      }
    }
  }
}
