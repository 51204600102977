.m-trade-bench-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: rem(8) rem(24);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: rem(24);
  background: rgba($black, 0.3);
  @include mh(10px);

  &,
  &-blank {
    height: rem(56);
    clear: both;
  }
}
