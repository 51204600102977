.language {
  margin-right: 12px;
  position: relative;

  .motion {
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 12px);
    z-index: 1;
    position: absolute;
    width: 120px;
    height: 0;
    overflow: hidden;

    .content {
      padding: 10px;
      box-sizing: border-box;
      background: #303044;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      font-size: 14px;

      span {
        color: #888;
        padding: 8px;
        transition: all 0.2s ease;

        &:hover {
          color: #fff;
          cursor: pointer;
          background: #212121;
          border-radius: 4px;
        }
      }
    }
  }

  .trigger {
    color: #8d8d8d;
    font-size: 14px;
    display: flex;
    align-items: center;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }
}

 @media only screen and (max-width: 480px) {
   .language {
     padding-left: rem(12);

     .motion {
       transform: translateX(0);
       position: unset;
     }

     .trigger {
       height: rem(48);
       font-size: rem(16);
     }
   }
 }
