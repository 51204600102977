.page-launchpad-item-ido {
  &-successful {
    p {
      text-align: center;
      font-size: 14px;
    }
  }

  .c-data-item {
    font-size: 14px;
  }

  .c-data-item-group {
    padding: 0;
  }

  .c-input-number-label label {
    font-size: 14px;
    padding-left: 0;
  }

  .web-c-skeleton-content .web-c-skeleton-row {
    width: 80px !important;
    height: 20px;
  }
}

.mobile-mode {
  .page-launchpad-item-ido {
    &-successful {
      p {
        font-size: rem(14);
      }
    }

    .c-data-item {
      font-size: rem(14);
    }

    .c-input-number-label label {
      font-size: rem(14);
    }

    .web-c-skeleton-content .web-c-skeleton-row {
      width: rem(80) !important;
      height: rem(20);
    }
  }
}
